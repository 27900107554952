<template>
    <div class="popup" :class="classObj">
        <van-popup :value="show" @click-overlay="close" @open="open">
            <div class="content">
                <statusBar></statusBar>
                <div class="header">
                    <div>{{ $t("treasureChest.title") }}</div>
                    <div class="close">
                        <i class="iconfont icon-s-cuowu-guanbi" @click="close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="invite_box main_box">
                        <div>
                            <div class="top_box">
                                <img src="@/assets/share/trumpet.png" alt="">
                                <div>
                                    <div>
                                        {{ $t("share.inviteFriend") }}
                                    </div>
                                    <div>
                                        {{ CurrencyType }} {{ shareData.extendRewardsAmt }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="invite_title">
                                    {{ $t("share.InviteVia") }}
                                </div>
                                <div class="link_box main_box">
                                    <div class="qrcode-canvas">
                                        <!-- <img src="@/assets/share/facebook.png" alt=""> -->
                                        <canvas ref="qrcodeCanvas"></canvas>
                                    </div>
                                    <div class="link_info">
                                        <div class="my_link" v-if="!isHideShareLink" @click="onCopy(inviteUrl)">
                                            <div>{{ $t("share.link") }}</div>
                                            <div class="info">{{ inviteUrl }}</div>
                                            <div class="btn">
                                                <span class="iconfont icon-fuzhiwenjian"></span>
                                            </div>
                                        </div>
                                        <div class="my_link" v-if="!isHideShareLink"
                                            @click="onCopy(shareData.inviteCode)">
                                            <div>{{ $t("share.code") }}</div>
                                            <div class="info">{{ shareData.inviteCode }}</div>
                                            <div class="btn">
                                                <span class="iconfont icon-fuzhiwenjian"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="OR">
                                    {{ $t("share.or") }}
                                </div>
                                <div class="platforms">
                                    <div class="platform" v-for="(item, index) in platforms" :key="index">
                                        <img :src="item.icon" alt="" v-if="item.icon && item.url"
                                            @click="openOutLink(item.url)">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="share_info">
                        <div class="top_box">
                            <div class="qrcode-canvas">
                                <div id="qrcode-box" class="qrcode-box">
                                    <canvas ref="qrcodeCanvas"></canvas>
                                </div>
                                <div class="save" @click="saveQRCode">
                                    {{ $t('treasureChest.save') }}
                                </div>
                            </div>
                            <div class="info_msg">
                                <div class="info_box" @click.stop="isFocus = true">
                                    <div class="title">{{ ($t('treasureChest.ReferralLink')) }}</div>
                                    <div class="my_link" :class="{ 'act': isFocus }"
                                        @click="!isHideShareLink && onCopy(inviteUrl)">
                                        <div v-if="!isHideShareLink">{{ inviteUrl }}</div>
                                        <span v-if="!isHideShareLink" class="iconfont icon-fuzhiwenjian"></span>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="title">{{ $t('treasureChest.directSubordinates') }}</div>
                                    <div class="num">{{ shareData.todayRechargeNum || 0 }}</div>
                                </div>
                                <div class="item">
                                    <div class="title">{{ $t('treasureChest.ReferralCode') }}</div>
                                    <div class="num" @click="!isHideShareLink && onCopy(shareData.inviteCode)"
                                        v-if="!isHideShareLink">{{ shareData.inviteCode }}</div>
                                    <span v-if="!isHideShareLink" class="iconfont icon-fuzhiwenjian"
                                        @click="onCopy(shareData.inviteCode)"></span>
                                </div>
                            </div>
                        </div>
                        <div class="bottom_box">
                            <div class="platforms_item" v-for="(item, index) in platforms" :key="index"
                                @click="onNavToByIsLogin({ url: item.url, type: item.linkType })">
                                <img :src="item.icon" />
                                <div class="title">{{ item.title }}</div>
                            </div>
                        </div>
                    </div> -->
                    <div v-if="boxRechargeAmt || boxGrandTotalBetting" class="chest-info">
                        <div>{{ $t('treasureChest.chestInfoTips1') }}</div>
                        <div v-if="boxRechargeAmt">
                            {{ $t('treasureChest.chestInfoTips2') }} <span class="val">{{ boxRechargeAmt }}{{ CurrencyType }}</span> {{
                                $t('treasureChest.chestInfoTips3') }}
                        </div>
                        <div v-if="boxGrandTotalBetting">
                            {{ $t('treasureChest.chestInfoTips4') }} <span class="val">{{ boxGrandTotalBetting }}</span>
                            {{
                                $t('treasureChest.chestInfoTips5') }}
                        </div>
                    </div>
                    <div v-if="extendRewardsType && TreasureChestList.length" class="TreasureChest">
                        <div class="TreasureChestInset">
                            <div class="TreasureChest-row" v-for="(item, index) in TreasureChestList" :key="index">
                                <div class="TreasureChest-item" v-for="(citem, cindex) in item" :key="cindex">
                                    <div class="img">
                                        <img v-if="citem.boxStatus == 1" src="@/assets/share/TreasureChest2.png" />
                                        <img v-if="citem.boxStatus == 2" src="@/assets/share/TreasureChest1.png" />
                                        <img v-if="citem.boxStatus == 3" style="opacity: .3;"
                                            src="@/assets/share/TreasureChest1.png" />
                                        <div class="arrow iconfont icon-jiantou1"></div>
                                    </div>
                                    <div v-if="citem.invitePeople == 1" class="txt">{{ citem.invitePeople }} {{ $t('treasureChest.People') }}</div>
                                    <div v-if="citem.invitePeople > 1 && citem.invitePeople < 10" class="txt">{{ citem.invitePeople }} {{ $t('treasureChest.People2') }}</div>
                                    <div v-if="citem.invitePeople >= 10" class="txt">{{ citem.invitePeople }} {{ $t('treasureChest.People3') }}</div>
                                    <div class="val">{{ citem.bonus }}</div>
                                </div>
                            </div>
                            <div v-if="extendRewardsType == 'immobilization'" class="TreasureChest-row">
                                <div class="TreasureChest-item">
                                    <div class="txt more">...</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="proxy_network">
                        <div class="proxy_title">{{ ($t('treasureChest.inviteTitle')) }}</div>
                        <div class="tutorialDetail">
                            <div v-html="ruleDesc"></div>
                        </div>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import QRCode from 'qrcode'
import { mapState } from 'vuex'
import statusBar from '@/components/statusBar'
import { getMemberExtend } from '@/api/treasureChest'
import { getShareinfo, getHideShareStatus } from '@/api/share'
import { inviteCodeKey } from '@/common/http'
import html2canvas from 'html2canvas'
let boxList
export default {
    name: 'TreasureChest',
    components: { statusBar },
    data() {
        return {
            isFocus: false,
            inviteUrl: '',
            isHideShareLink: false,
            shareData: {},
            platforms: [],
            boxRechargeAmt: 0, // 完成宝箱需要多少代收金额，
            boxGrandTotalBetting: 0, // 完成宝箱需要多少下注金额
            extendRewardsType: '',
            TreasureChestList: [],
            ruleDesc: ''
        }
    },
    computed: {
        isLogin() {
            return !!this.$store.getters.token
        },
        ...mapState({
            device: state => state.app.device
        }),
        classObj() {
            return {
                mobile: this.device === 'mobile'
            }
        },
        eventId() {
            return this.$store.state.user.TreasureChestEventId
        },
        show: {
            get() {
                return this.$store.getters.isShowTreasureChestPopup
            },
            set(val) {
                return val
            }
        }
    },
    methods: {
        open() {
            this.getShareInfo()
            this.GetPlatforms()
        },
        close() {
            this.$store.dispatch('user/setIsShowTreasureChestPopup', { id: '', show: false })
        },
        getHideShareStatus() {
            getHideShareStatus().then((res) => {
                if (!res) return
                if (res.code) return
                this.isHideShareLink = res.result
            })
        },
        getShareInfo() {
            getShareinfo().then(res => {
                if (!res) return
                if (res.code) return
                const D = res.result
                this.inviteUrl = window.location.origin + '?' + inviteCodeKey + '=' + D.inviteCode
                this.$nextTick(() => {
                    QRCode.toCanvas(this.$refs.qrcodeCanvas, this.inviteUrl, error => {
                        if (error) console.error(error)
                    })
                })
                this.shareData = D
            })
            getMemberExtend().then((res) => {
                if (!res) return
                if (res.code) return
                const D = res.result
                this.ruleDesc = D.rule
                this.boxRechargeAmt = D.boxRechargeAmt || 0
                this.boxGrandTotalBetting = D.boxGrandTotalBetting || 0
                // this.rule = D.rule
                this.extendRewardsType = D.extendRewardsType // 空的不显示 immobilization 都显示
                this.TreasureChestList = [[]]
                boxList = []
                if (D.extendRewardsType === 'immobilization') {
                    this.setBoxList(D.extendFixedRewardDetailsList, (D.bonusNumSumTotal || 0), D.invitePeopleNum, 1)
                    if (boxList.length) {
                        boxList.forEach((x, i) => {
                            if (D.bonusNumSumTotal > (boxList.length - 1)) {
                                if (D.bonusNumDayTotal >= (boxList.length - 1)) {
                                    x.boxStatus = 2
                                }
                                if (D.bonusNumDayTotal < (boxList.length - 1)) {
                                    if (i < ((D.bonusNumDayTotal) - (boxList.length - 1))) {
                                        x.boxStatus = 1
                                    } else {
                                        x.boxStatus = 2
                                    }
                                }
                            } else {
                                if (i < D.bonusNumIssuedTotal) {
                                    x.boxStatus = 1
                                } else if (i < (D.bonusNumIssuedTotal + D.bonusNumDayTotal)) {
                                    x.boxStatus = 2
                                } else {
                                    x.boxStatus = 3
                                }
                            }
                        })
                    }
                } else if (D.extendRewardsType === 'phase') {
                    boxList = D.extendRewardsDetailsList
                    if (boxList.length) {
                        boxList.forEach((x, i) => {
                            x.boxStatus = 3
                            x.bonus = x.rewardsAmount
                            x.invitePeople = x.validCount
                            if (Number(D.firstChargerCountDayTotal) + Number(D
                                .firstChargerCountIssuedTotal) >= Number(x.validCount)) {
                                x.boxStatus = 2
                            }
                            if (D.inviteBonusOrderState === 'unDistribution') {
                                if ((Number(D.firstChargerCountIssuedTotal) - Number(D
                                    .yesterdayInviteBoxInviteNum)) >= Number(x.validCount)) {
                                    x.boxStatus = 1
                                }
                            } else {
                                if (Number(D.firstChargerCountIssuedTotal) >= Number(x.validCount)) {
                                    x.boxStatus = 1
                                }
                            }
                        })
                    }
                }
                if (boxList.length) {
                    boxList[boxList.length - 1].boxStatus = 3
                    boxList.forEach((x, i) => {
                        if (i < this.TreasureChestList.length * 4) {
                            this.TreasureChestList[this.TreasureChestList.length - 1].push(x)
                        } else {
                            this.TreasureChestList[this.TreasureChestList.length] = [x]
                        }
                    })
                }
            })
        },
        setBoxList(list, tempTotal, inviteNum, isFirst) {
            const chestCount = 40
            let total = tempTotal
            if (tempTotal < chestCount && isFirst === 1) total = (chestCount - 1)
            list.forEach((x, i) => {
                if (x.endCount > total) {
                    if (total > (chestCount - 1)) {
                        if (x.startCount <= (total - (chestCount - 1))) {
                            const obj1 = {
                                bonus: x.bonus,
                                invitePeople: Number(total + 1) * inviteNum
                            }
                            if (boxList.length < chestCount) boxList.push(obj1)
                            for (let j = 0; j < (chestCount - 1); j++) {
                                const obj = {
                                    bonus: x.bonus,
                                    invitePeople: Number(total - j) * inviteNum
                                }
                                if (boxList.length < chestCount) boxList.unshift(obj)
                                else break
                            }
                        } else {
                            const obj1 = {
                                bonus: x.bonus,
                                invitePeople: Number(total + 1) * inviteNum
                            }
                            if (boxList.length < chestCount) boxList.push(obj1)
                            for (let j = 0; j < (chestCount - 1); j++) {
                                if (Number(total - j) >= x.startCount) {
                                    const obj = {
                                        bonus: x.bonus,
                                        invitePeople: Number(total - j) * inviteNum
                                    }
                                    if (boxList.length < chestCount) boxList.unshift(obj)
                                    else break
                                } else {
                                    if (boxList.length < chestCount) this.setBoxList(list, (x.startCount - 1), inviteNum)
                                    break
                                }
                            }
                        }
                    } else {
                        const obj1 = {
                            bonus: x.bonus,
                            invitePeople: Number(total + 1) * inviteNum
                        }
                        if (boxList.length < chestCount) boxList.push(obj1)
                        for (let j = 0; j < total; j++) {
                            if (Number(total - j) >= x.startCount) {
                                const obj = {
                                    bonus: x.bonus,
                                    invitePeople: Number(total - j) * inviteNum
                                }
                                if (boxList.length < chestCount) boxList.unshift(obj)
                                else break
                            } else {
                                if (boxList.length < chestCount) this.setBoxList(list, (x.startCount - 1), inviteNum)
                                break
                            }
                        }
                    }
                }
            })
        },
        async saveQRCode() {
            try {
                const qrElement = document.getElementById('qrcode-box')
                const canvas = await html2canvas(qrElement, { scale: 3 })
                const img = canvas.toDataURL('image/png')
                const link = document.createElement('a')
                link.href = img
                link.setAttribute('download', 'qrcode.png')
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            } catch (error) {
                console.error('Error saving QR code:', error)
            }
        },
        GetPlatforms() {
            this.platforms = []
            const systemConfig = this.$store.getters.sysConfig
            const facebook = systemConfig.facebook?.trim()
            if (facebook) {
                const platform1 = {
                    id: 0,
                    icon: require('@/assets/share/icon_fackbook.png'),
                    title: 'Facebook',
                    url: facebook,
                    linkType: 'href'
                }
                this.platforms.push(platform1)
            }
            const telegram = systemConfig.telegram?.trim()
            if (telegram) {
                const platform2 = {
                    id: 1,
                    icon: require('@/assets/share/icon_telegram.png'),
                    title: 'Telegram',
                    url: telegram,
                    linkType: 'href'
                }
                this.platforms.push(platform2)
            }
            const instagram = systemConfig.instagram?.trim()
            if (instagram) {
                const platform3 = {
                    id: 2,
                    icon: require('@/assets/share/icon_ins.png'),
                    title: 'Instagram',
                    url: instagram,
                    linkType: 'href'
                }
                this.platforms.push(platform3)
            }
            const youTube = systemConfig.youTube?.trim()
            if (youTube) {
                const platform4 = {
                    id: 3,
                    icon: require('@/assets/share/icon_youtube.png'),
                    title: 'Youtube',
                    url: youTube,
                    linkType: 'href'
                }
                this.platforms.push(platform4)
            }
            const whatsapp = systemConfig.whatsapp?.trim()
            if (whatsapp) {
                const platform5 = {
                    id: 4,
                    icon: require('@/assets/share/icon_whatsapp.png'),
                    title: 'Whatsapp',
                    url: whatsapp,
                    linkType: 'href'
                }
                this.platforms.push(platform5)
            }
            const twitter = systemConfig.twitter?.trim()
            if (twitter) {
                const platform6 = {
                    id: 5,
                    icon: require('@/assets/share/icon_x.png'),
                    title: 'X',
                    url: twitter,
                    linkType: 'href'
                }
                this.platforms.push(platform6)
            }
            const tiktok = systemConfig.tiktok?.trim()
            if (tiktok) {
                const platform7 = {
                    id: 6,
                    icon: require('@/assets/share/icon_tiktok.png'),
                    title: 'TikTok',
                    url: tiktok,
                    linkType: 'href'
                }
                this.platforms.push(platform7)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';

.popup {
    &.mobile {

        .van-popup {
            &.van-popup--center {
                width: 100% !important;
                height: 100% !important;
                max-width: 100% !important;
                min-height: 100% !important;
                max-height: 100% !important;
            }

            .content {
                width: 100% !important;
                // padding-bottom: .4rem;
                height: 100% !important;

                .header {
                    height: .94rem;
                    padding: 0 .28rem;

                    &>div:first-child {
                        font-size: .3rem;
                    }

                    .close {
                        font-size: .3rem;
                    }
                }
            }
        }
    }

    .van-popup {
        background: transparent;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .content {
            background: $bg-popup-color;
            width: 500px;
            height: 700px;
            border-radius: .24rem;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                height: 76px;
                padding: 0 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $bg-navbar-color;

                &>div:first-child {
                    font-size: 20px;
                    font-weight: bold;
                    color: #fff;
                    text-align: left;
                    line-height: .33rem;
                }

                .close {
                    color: $font-iconfont-color;
                    font-size: 16px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s;

                    &:hover {
                        color: #ffffff;
                        rotate: -180deg;
                    }
                }
            }

            .box {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                overflow-y: auto;
                padding-bottom: .2rem;

                .invite_box {
                    margin-top: 0;
                    padding: 32px 10%;
                    background-position: center top;
                    border-radius: .24rem;
                    margin: 0 .2rem;
                    margin-top: .2rem;
                    padding: 0.3rem;
                    background: $bg-menu-color;
                    // background-image: url(@/assets/share/share_bg.png);
                    // background-position: center bottom;
                    // background-size: 100% auto;
                    // background-repeat: no-repeat;
                    box-sizing: border-box;

                    .top_box {
                        display: flex;

                        >img {
                            width: 1.52rem;
                            height: 1.44rem;
                            margin-right: .2rem;
                        }

                        >div {
                            >div {
                                font-weight: 700;
                                text-align: left;
                                background: linear-gradient(90deg, #f3df00, #ff7300);
                                -webkit-background-clip: text;
                                background-clip: text;
                                color: transparent;
                                font-size: .36rem;
                                // height: 0.42rem;

                                &:first-child {
                                    color: #ffffff;
                                    font-size: .28rem;
                                    font-weight: 400;
                                    line-height: 0.34rem;
                                }
                            }
                        }
                    }

                    .invite_title {
                        color: #ffffff;
                        font-size: .28rem;
                        margin: .2rem 0 .12rem;
                        text-align: left;
                        font-weight: 600;
                    }

                    .link_box {
                        display: flex;
                        align-items: center;
                        column-gap: 0.12rem;

                        .qrcode-canvas {
                            background: #ffffff;
                            width: 1.72rem;
                            height: 1.72rem;
                            overflow: hidden;
                            position: relative;

                            canvas {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                width: 115% !important;
                                height: 115% !important;
                            }
                        }

                        .link_info {
                            flex: 1;
                            overflow: hidden;

                            .my_link {
                                display: flex;
                                justify-content: space-between;
                                font-size: .24rem;
                                padding: 0.1rem 0.1rem 0.1rem .2rem;
                                border: .02rem solid $border-input-color;
                                border-radius: .24rem;
                                // height: .8rem;
                                line-height: .6rem;
                                position: relative;
                                background: $bg-input-color;
                                line-height: .38rem;
                                cursor: pointer;

                                &:first-child {
                                    margin-bottom: .12rem;
                                }

                                >div {
                                    color: #ffffff;

                                    &:first-child {
                                        width: .72rem;
                                        text-align: left;
                                        color: $font-label-color;
                                        word-break: break-all;
                                        padding-right: .1rem;
                                    }

                                    &.info {
                                        flex: 1;
                                        text-align: left;
                                        overflow: hidden;
                                        padding-right: .1rem;
                                        white-space: wrap;
                                        flex-shrink: 0;
                                        display: -webkit-box;
                                        word-break: break-all;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    &.btn {
                                        background: $bg-btn-color;
                                        color: $font-btn-color;
                                        padding: .19rem;
                                        border-radius: .06rem;
                                        font-weight: bold;
                                        height: max-content;
                                    }
                                }
                            }
                        }

                    }

                    .OR {
                        color: $font-sub-color;
                        height: .34rem;
                        margin: .2rem 0 .12rem;
                        font-size: .28rem;
                        text-align: center;
                        position: relative;

                        &::before {}
                    }

                    .platforms {
                        display: flex;
                        justify-content: space-around;

                        .platform {
                            img {
                                width: .7rem;
                                height: .7rem;
                            }
                        }
                    }
                }

                .share_info {
                    background: #0F212E;
                    border: .01rem solid #2F4553;
                    border-radius: .12rem;
                    padding: .2rem .2rem .24rem;
                    margin: 0 .2rem;
                    margin-top: .18rem;

                    .top_box {
                        display: flex;
                        justify-content: space-between;
                        column-gap: .3rem;

                        .qrcode-canvas {
                            width: 1.54rem;

                            .qrcode-box {
                                width: 100%;
                                height: 1.54rem;
                                background: #393939;
                                // border: .08rem solid #ffffff;
                                border-radius: .07rem;
                                border-bottom-left-radius: 0;
                                border-bottom-right-radius: 0;
                                position: relative;

                                canvas {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 100% !important;
                                    height: 100% !important;
                                }
                            }

                            .save {
                                width: 100%;
                                line-height: .34rem;
                                font-size: .22rem;
                                color: #FFFFFF;
                                background: #1475E1;
                                border-radius: .1rem;
                                border-top-left-radius: 0;
                                border-top-right-radius: 0;
                                display: flex;
                                justify-content: center;
                                text-align: center;
                                padding: .04rem .04rem .04rem;
                                cursor: pointer;

                                .iconfont {
                                    font-size: .3rem;
                                    margin-right: .1rem;
                                }
                            }
                        }

                        .info_msg {
                            width: calc(100% - 1.54rem);
                            flex: 1;
                            display: flex;
                            flex-direction: column;

                            .info_box {
                                margin-bottom: .2rem;

                                .title {
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: .24rem;
                                    color: #FFFFFF;
                                }

                                .my_link {
                                    margin-top: .1rem;
                                    padding: .14rem .6rem .14rem .2rem;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    border-radius: .1rem;
                                    border: .02rem solid #2f4553;
                                    font-size: .22rem;
                                    color: #B1BAD3;
                                    position: relative;

                                    &.act {
                                        border: .02rem solid #3b94ed;
                                        box-shadow: 0 0 0 .04rem #113758;
                                    }

                                    &>div {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        line-height: .28rem;
                                        text-align: left;
                                    }
                                }
                            }

                            .item {
                                display: flex;
                                align-items: center;
                                position: relative;

                                .title {
                                    font-size: .24rem;
                                    color: #687B88;
                                    line-height: .38rem;
                                    margin-right: .3rem;
                                }

                                .num {
                                    font-size: .24rem;
                                    color: #FFFFFF;
                                }

                                .iconfont {
                                    position: relative;
                                    transform: translateY(-60%);
                                    margin-left: .3rem;
                                }
                            }

                            .iconfont {
                                position: absolute;
                                top: 50%;
                                right: .2rem;
                                transform: translateY(-50%);
                                width: .36rem;
                                height: .38rem;
                                font-size: .32rem;
                                color: #fff;
                                cursor: pointer;
                            }
                        }
                    }

                    .bottom_box {
                        display: flex;
                        column-gap: .2rem;
                        margin-top: .2rem;
                    }

                    .platforms_item {
                        text-align: center;
                        cursor: pointer;

                        img {
                            width: .7rem;
                            height: .7rem;
                            border-radius: .16rem;
                        }

                        .title {
                            font-size: .22rem;
                            color: #FFFFFF;
                            margin-top: .1rem;
                        }
                    }
                }

                .chest-info {
                    background: $bg-menu-color;
                    border-radius: .14rem;
                    margin: .2rem 0;
                    color: $font-label-color;
                    // border: .01rem solid #2F4553;
                    margin: 0 .2rem;
                    margin-top: .18rem;
                    text-align: center;
                    font-size: .24rem;
                    padding: .15rem .24rem;

                    &>div {
                        padding: .2rem;
                        line-height: .26rem;

                        .val {
                            font-size: .28rem;
                            color: #fff;
                            margin: 0 .08rem;
                        }
                    }

                    &>div:nth-child(2) {
                        background: rgba(255, 255, 255, .1);
                        border-radius: .14rem;
                    }
                }

                .TreasureChest {
                    background: $bg-menu-color;
                    // border: .02rem solid #2F4553;
                    border-radius: .16rem;
                    padding: .38rem .24rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 .2rem;
                    margin-top: .18rem;
                    padding-bottom: .05rem;

                    .TreasureChestInset {
                        display: inline-block
                    }

                    .TreasureChest-row {
                        display: flex;

                        &:nth-child(2n) {
                            flex-direction: row-reverse;

                            .TreasureChest-item:nth-child(-n+3) {
                                margin-right: 0;
                                margin-left: .56rem;

                                &>div.img {
                                    .arrow {
                                        position: absolute;
                                        bottom: calc(50% - .3rem);
                                        right: auto;
                                        left: -0.56rem;
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                        }

                        &:nth-child(2n+1) {
                            .TreasureChest-item {
                                &:last-child {
                                    margin-right: 0;

                                    &>div.img {
                                        .arrow {
                                            transform: rotate(90deg);
                                            // bottom: -120upx;
                                            right: calc(50% - .3rem)
                                        }
                                    }
                                }
                            }
                        }

                        &:last-child {
                            .TreasureChest-item {
                                &:last-child {
                                    &>div.img {
                                        .arrow {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .TreasureChest-item {
                            width: calc((100% - 1.68rem)/3);
                            margin-right: .56rem;
                            margin-bottom: .6rem;

                            &:last-child {
                                margin-right: 0;

                                &>div.img {
                                    .arrow {
                                        transform: rotate(90deg);
                                        bottom: -1.48rem;
                                        right: calc(50% - .3rem)
                                    }
                                }
                            }

                            &>div.img {
                                position: relative;

                                img {
                                    width: 1.32rem;
                                    height: 1.2rem;
                                }

                                .arrow {
                                    position: absolute;
                                    width: .56rem;
                                    height: .48rem;
                                    font-size: .28rem;
                                    bottom: calc(50% - .3rem);
                                    color: #979798;
                                    right: -0.56rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }

                            .txt {
                                color: #ffffff;
                                font-size: .2rem;
                                width: 100%;
                                text-align: center;
                                line-height: .36rem;

                                // white-space: nowrap;
                                &.more {
                                    font-size: .88rem;
                                    padding-right: .3rem;
                                }
                            }

                            .val {
                                width: 100%;
                                text-align: center;
                                font-size: .28rem;
                                color: $font-theme-color;
                            }
                        }
                    }
                }

                .proxy_network {

                    .proxy_title {
                        font-size: .3rem;
                        background: linear-gradient(0deg, #FF940A 0%, #FAC988 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        font-weight: bold;
                        text-align: left;
                        margin-top: .38rem;
                        margin-left: .28rem;
                    }

                    .tutorialDetail {
                        background: $bg-menu-color;
                        // border: .02rem solid #2F4553;
                        border-radius: .16rem;
                        padding: .2rem .24rem;
                        margin: 0 .2rem;
                        margin-top: .18rem;

                        div {
                            ::v-deep img {
                                width: 100% !important;
                                height: auto;
                            }

                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
</style>
