<template>
    <div class="tabbar-content">
        <div class="tabbar">
            <div class="item" :class="{ 'act': actIdx == 1 }" @click.stop="change(1)">
                <van-image v-if="actIdx == 1" width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/homeAct.png')" />
                <van-image v-else width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/home.png')" />
                <div class="item-text">{{ $t("tabbar.Lobby") }}</div>
            </div>
            <div class="item" :class="{ 'act': actIdx == 2 }" @click.stop="change(2)">
                <van-image v-if="actIdx == 2" width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/rechargeAct.png')" />
                <van-image v-else width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/recharge.png')" />
                <div class="item-text">{{ $t("tabbar.Deposit") }}</div>
            </div>
            <div class="item center" :class="{ 'act': actIdx == 3 }" @click.stop="change(3)">
                <div class="center-img">
                    <div class="center-inset">
                        <van-image v-if="actIdx == 3" width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                            :src="require('./../../assets/tabbar/shareAct.png')" />
                        <van-image v-else width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                            :src="require('./../../assets/tabbar/share.png')" />
                    </div>
                </div>
                <div class="item-text">{{ $t("tabbar.Affiliate") }}</div>
            </div>
            <div class="item" :class="{ 'act': actIdx == 4 }" @click.stop="change(4)">
                <van-image v-if="actIdx == 4" width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/eventAct.png')" />
                <van-image v-else width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/event.png')" />
                <div class="item-text">{{ $t("tabbar.Promotion") }}</div>
            </div>
            <div class="item" :class="{ 'act': actIdx == 5 }" @click.stop="change(5)">
                <van-image v-if="actIdx == 5" width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/personAct.png')" />
                <van-image v-else width=".6rem" height=".6rem" :show-loading="false" fit="contain"
                    :src="require('./../../assets/tabbar/person.png')" />
                <div class="item-text">{{ $t("tabbar.Account") }}</div>
            </div>
        </div>
        <div v-if="isShowSaveVersion" class="pwa" @click="onShowSaveVersionPopup">
            <div class="iconfont icon-jiahao"></div>
            <div class="txt">{{ $t('downApp.SaveVersionDesktop') }}</div>
            <div class="iconfont icon-s-cuowu-guanbi" @click.stop="onCloseSaveVersion"></div>
        </div>
        <pwaIosPopup ref="pwaIosPopup" :isToHome="false"></pwaIosPopup>
        <SaveVersionPopup ref="SaveVersionPopup" :isToHome="false"></SaveVersionPopup>
    </div>
</template>

<script>
import pwaIosPopup from '@/views/downAppPWA/modules/pwaIosPopup'
import SaveVersionPopup from '@/views/downAppPWA/modules/SaveVersionPopup'
import { isPWA, getPlatform } from '@/utils'
export default {
    name: 'Tabbar',
    components: { pwaIosPopup, SaveVersionPopup },
    data() {
        return {
            actIdx: 1,
            isSaveVersionDesktop: true
        }
    },
    computed: {
        isShowSaveVersion() {
            return this.$store.state.system.isSaveVersionDesktop === 1 && this.isSaveVersionDesktop && (!isPWA()) && getPlatform() !== 'android' && getPlatform() !== 'Android'
        }
    },
    created() {
        const path = this.$route.path
        if (path === '/Home') {
            this.actIdx = 1
        }
        if (path === '/Affiliate') {
            this.actIdx = 3
        }
        if (path === '/Event') {
            this.actIdx = 4
        }
        if (path === '/Person') {
            this.actIdx = 5
        }
    },
    watch: {
        $route: {
            handler: function (to, from) {
                const path = this.$route.path
                if (path === '/Home') {
                    this.actIdx = 1
                } else if (path === '/Affiliate') {
                    this.actIdx = 3
                } else if (path === '/Event') {
                    this.actIdx = 4
                } else if (path === '/Person') {
                    this.actIdx = 5
                } else {
                    this.actIdx = -1
                }
            },
            deep: true
        }
    },
    methods: {
        change(idx) {
            if (this.actIdx === idx) return
            if (idx !== 2) {
                this.actIdx = idx
            }
            if (idx === 1) {
                this.onNavTo({ url: '/Home', replace: 1 })
            } else if (idx === 2) {
                this.$store.dispatch('user/setIsShowDepositPopup', true)
            } else if (idx === 3) {
                this.onNavTo({ url: '/Affiliate', replace: 1 })
            } else if (idx === 4) {
                this.onNavTo({ url: '/Event', replace: 1 })
            } else if (idx === 5) {
                this.onNavTo({ url: '/Person', replace: 1 })
            }
        },
        onCloseSaveVersion() {
            this.isSaveVersionDesktop = false
        },
        onShowSaveVersionPopup() {
            this.isSaveVersionDesktop = false
            if (getPlatform() === 'ios') {
                this.$refs.pwaIosPopup.open()
            } else {
                this.$refs.SaveVersionPopup.open()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';
$bg-tabbar-color: #911416;

.tabbar {
    display: none;
    // max-width: var(--theme-max-width);
}

.tabbar-content {
    display: none;
}

.tabbar-content {
    position: relative;

    .pwa {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: .62rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.8);
        padding: 0 .26rem;
        color: #1678ff;
        font-size: .34rem;
        z-index: 101;
        line-height: .34rem;
    }
}

.tabbar {
    // position: fixed;
    // bottom: 0;
    z-index: 100;
    position: relative;
    margin: 0 auto;
    height: 1.24rem;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $bg-tabbar-color;
    filter: drop-shadow(0 -4px 10px rgba(0, 0, 0, .3));
    // box-shadow: 0rpx -6rpx 13rpx 0rpx rgba(202,255,207,.22);

    .item {
        width: 20%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        overflow: visible;
        position: relative;
        cursor: pointer;
        padding-top: 0.08rem;
        padding-bottom: .11rem;

        .item-text {
            height: .24rem;
            line-height: .24rem;
            width: 100%;
            text-align: center;
            font-size: .2rem;
            color: #D3B0B1;
            margin-top: .06rem;
            position: relative;
            z-index: 9;
        }

        &.act {
            .item-text {
                color: #ffffff;
            }

            &.center {
                .center-img {
                    .center-inset {
                        border: 1px solid #000;
                        background: $font-theme-color;
                    }
                }
            }
        }

        &.center {
            position: relative;

            .center-img {
                position: absolute;
                width: 1.2rem;
                height: 1.2rem;
                background: $bg-tabbar-color;
                border-radius: 50%;
                top: -12px;
                display: flex;
                align-items: center;
                justify-content: center;
                left: calc(50% - .6rem);

                .center-inset {
                    border: 1px solid $font-theme-color;
                    border-radius: 50%;
                    width: .95rem;
                    height: .95rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: linear-gradient(180deg, rgba(255, 220, 74, .1), rgba(255, 220, 74, .45) 77%);
                    padding-bottom: .05rem;
                }
            }
        }
    }
}
</style>
