<template>
    <div :class="classObj" class="app-wrapper">
        <div v-if="device === 'mobile' && sidebar.opened" class="drawer-bg" @click="handleClickOutside"></div>
        <!-- 头部 -->
        <Header />
        <div class="main-container">
            <!-- 左边侧边栏 -->
            <Sidebar class="sidebar-container" />
            <app-main />
            <!-- 右边消息通知 -->
            <Notification class="Notification-container" />
        </div>
        <!-- 底部tabbar -->
        <Tabbar />
        <MenuPop />
        <myProfilePopup></myProfilePopup>
        <settingPopup></settingPopup>
        <betHistoryPopup></betHistoryPopup>
        <transactionPopup></transactionPopup>
        <bonusRainPopup></bonusRainPopup>
        <downAppPopup></downAppPopup>
        <checkInPop></checkInPop>
        <pendingPop></pendingPop>
        <turntablePopup></turntablePopup>
        <lossSupportPopup></lossSupportPopup>
        <rebatePopup></rebatePopup>
        <winRankListPopup></winRankListPopup>
        <LotteryPopup></LotteryPopup>
        <treasureChestPopup></treasureChestPopup>
        <depositTotalPopup></depositTotalPopup>
        <depositRepeatPopup></depositRepeatPopup>
        <depositRepeatUsdtPopup></depositRepeatUsdtPopup>
        <!-- <loginPopup></loginPopup> -->
    </div>
</template>

<script>
import AppMain from './components/AppMain.vue'
import Header from './components/Header.vue'
import Sidebar from './components/Sidebar.vue'
import Notification from './components/Notification.vue'
import Tabbar from './components/Tabbar.vue'
import ResizeMixin from './mixin/ResizeHandler'
import MenuPop from '@/views/home/modules/menuPop.vue'
import myProfilePopup from '@/components/myProfilePopup/index.vue'
import settingPopup from '@/components/settingPopup/index.vue'
import betHistoryPopup from '@/components/betHistory/index.vue'
import transactionPopup from '@/components/transactionPopup/index.vue'
import bonusRainPopup from '@/components/bonusRainPopup/index.vue'
import downAppPopup from '@/components/downAppPopup/index.vue'
import checkInPop from '@/components/checkInPopup/index.vue'
import pendingPop from '@/components/pendingPopup/index.vue'
import turntablePopup from '@/components/turntablePopup/index.vue'
import lossSupportPopup from '@/components/lossSupportPopup/index.vue'
import rebatePopup from '@/components/rebatePopup/index.vue'
import winRankListPopup from '@/components/winRankListPopup'
import LotteryPopup from '@/components/LotteryPopup'
import treasureChestPopup from '@/components/treasureChestPopup'
import depositTotalPopup from '@/components/depositTotalPopup'
import depositRepeatPopup from '@/components/depositRepeatPopup'
import depositRepeatUsdtPopup from '@/components/depositRepeatUsdtPopup'
import { mapState } from 'vuex'
export default {
    name: 'Layout',
    components: { AppMain, Sidebar, Header, Tabbar, MenuPop, myProfilePopup, settingPopup, betHistoryPopup, Notification, transactionPopup, bonusRainPopup, downAppPopup, checkInPop, pendingPop, turntablePopup, lossSupportPopup, winRankListPopup, rebatePopup, LotteryPopup, treasureChestPopup, depositTotalPopup, depositRepeatPopup, depositRepeatUsdtPopup },
    mixins: [ResizeMixin],
    computed: {
        ...mapState({
            sidebar: state => state.app.sidebar,
            device: state => state.app.device,
            isShowNotification: state => state.app.isShowNotification
        }),
        classObj() {
            const obj = {
                hideSidebar: !this.sidebar.opened,
                openSidebar: this.sidebar.opened,
                withoutAnimation: this.sidebar.withoutAnimation,
                mobile: this.device === 'mobile',
                hideMenuPop: !this.showMenuPop,
                openNotification: this.isShowNotification
            }
            const meta = this.$route.meta
            if (meta) {
                const isMobileOnlyCustomer = meta.isMobileOnlyCustomer
                const isMobileHideHeader = meta.isMobileHideHeader
                const isMobileHideFooter = meta.isMobileHideFooter
                const isMobileHideTabbar = meta.isMobileHideTabbar
                obj.isMobileOnlyCustomer = isMobileOnlyCustomer
                obj.isMobileHideHeader = isMobileHideHeader
                obj.isMobileHideFooter = isMobileHideFooter
                obj.isMobileHideTabbar = isMobileHideTabbar
            }
            return obj
        },
        showMenuPop() {
            if (this.device === 'mobile') {
                if (this.$route.path.indexOf('/Home') !== -1) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }
        }
    },
    methods: {
        handleClickOutside() {
            this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/common/styles/viriables.scss';

.app-wrapper {
    position: relative;
    height: 100vh;
    width: 100%;
    background-color: $bg-color;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &.mobile.openSidebar {
        position: fixed;
        top: 0;
    }

    &.hideMenuPop {
        ::v-deep .fold_icon {
            &.menus {
                display: none;
            }
        }

        ::v-deep .ant-dropdown {
            display: none;
        }
    }

    .main-container {
        flex: 1;
        width: 100%;
        height: 100%;
        position: relative;
    }

    .sidebar-container {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 99;
    }

    .Notification-container {
        position: fixed;
        top: 1rem;
        bottom: 0;
        right: 0;
        z-index: 99;
        // display: none;
        visibility: hidden;
        transform: translateX(100%);
        transition: all .3s;
    }

    &.openNotification {
        .Notification-container {
            // display: block;
            visibility: visible;
            transform: translateX(0);
        }

        ::v-deep .app-main {
            right: 280px !important;
        }
    }

    &.hideSidebar {
        .sidebar-container {
            width: 64px !important;

            ::v-deep .Opend {
                pointer-events: none;
                transform: translate3d(-100%, 0, 0);
            }

            ::v-deep .PC-hide {
                transform: translateY(-100%);
                transition-delay: .3s;
                transition-duration: .3s;
                pointer-events: auto;
            }
        }

        ::v-deep .app-main {
            left: 64px !important;
        }

        ::v-deep .pages-header {
            padding-left: 64px !important;
        }
    }

    &.mobile {
        .sidebar-container {
            width: 80% !important;

            ::v-deep .Opend {
                transition: transform .28s;
                width: 100% !important;
                padding-bottom: 1.5rem;
            }
        }

        &.hideSidebar {
            .sidebar-container {
                pointer-events: none;
                transition-duration: 0.3s;
                transform: translate3d(-100%, 0, 0);
            }
        }

        ::v-deep .app-main {
            left: 0 !important;
            right: 0 !important;

            .app-main-wrap {
                min-width: 100% !important;
                padding: 0 !important;
            }
        }

        ::v-deep .fixed-header {
            background: $bg-navbar-color;
        }

        ::v-deep .pages-header {
            padding-left: 0 !important;

            .user_action {
                min-width: 100% !important;
                padding: 0 !important;

                .deposit.deposit_PC {
                    display: none;
                }

                .deposit.withdraw_PC {
                    display: none;
                }

                .deposit.deposit_app {
                    display: block;
                }

                .person {
                    display: none;
                }
            }

            .Notification {
                display: none;
            }
        }

        ::v-deep .footer {
            padding: 0 !important;
            padding-bottom: .16rem !important;
        }

        ::v-deep .classify {
            display: block !important;
            min-width: 100% !important;
            padding: 0 !important;

            .classify-sub {
                width: 100% !important;

                &.act {
                    .classify-sub-title {
                        color: #fff;

                        &.arrow {
                            &::after {
                                top: .36rem;
                                transform: rotate(-45deg);
                            }
                        }
                    }

                    .classify-sub-list {
                        display: block;
                    }
                }

                .classify-sub-title {
                    text-transform: none;
                    color: #fff;
                    width: 100%;
                    padding: .2rem .28rem;
                    margin: 0;
                    position: relative;

                    &.arrow {
                        &::after {
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-radius: 1px;
                            border-top: 2px solid;
                            border-right: 2px solid;
                            border-color: #A9ACAB;
                            right: .28rem;
                            top: .28rem;
                            transform: rotate(135deg);
                            transition-duration: 0.3s;
                        }
                    }
                }

                .classify-sub-list {
                    display: none;
                    background: #270406;
                    padding: .1rem 0;

                    .classify-sub-item {
                        padding: 0 .8rem;
                        font-size: .24rem;
                        color: #8E746D;
                        line-height: .52rem;
                        margin: 0
                    }
                }

                &.icons {
                    padding: .2rem 0;

                    .classify-sub-title {
                        color: #fff;
                        font-size: .24rem;
                        width: 100%;
                        text-align: center;
                    }

                    .classify-sub-list {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #270406;
                    }
                }
            }
        }

        ::v-deep .desc {
            display: block;
            width: 100%;
            padding: 0 !important;
            min-width: 100% !important;
            margin-top: .1rem;

            .desc-item {
                width: 100%;
                padding: .2rem .3rem;
                box-sizing: border-box;

                .logo {
                    display: block;
                    margin: 0 auto;
                    margin-bottom: .24rem;
                }

                &:last-child {
                    text-align: center;

                    .copyright {
                        margin-top: .2rem;
                        font-size: .22rem;
                    }
                }

                .desc-item-logos {
                    margin-bottom: .24rem;
                    justify-content: center;

                    .logo {
                        margin: 0;
                    }
                }
            }
        }

        .Notification-container {
            display: none !important;
        }

        ::v-deep .tabbar {
            max-width: 100%;
            display: flex;
        }

        ::v-deep .tabbar-content {
            max-width: 100%;
            display: block;
        }
    }

    &.withoutAnimation {

        .main-container,
        .sidebar-container {
            transition: none;
        }
    }

    &.mobile {
        &.isMobileHideFooter {
            ::v-deep .footer {
                display: none;
            }
        }

        &.isMobileOnlyCustomer {
            ::v-deep .footer {
                .classify {
                    .classify-sub {
                        &.hide {
                            display: none !important;
                        }
                    }
                }

                .desc {
                    display: none !important;
                }
            }
        }

        &.isMobileHideHeader {
            ::v-deep .fixed-header {
                display: none;
            }
        }

        &.isMobileHideTabbar {
            ::v-deep .tabbar-content {
                display: none;
            }
        }
    }
}

.drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 98;
}

html[data-device-nodesktop="1"] {
    .sidebar-container {
        top: 1rem;
    }
}

html[data-device-nodesktop="0"] {
    .sidebar-container {
        top: 0;
    }

    .Notification-container {
        display: none;
    }
}
</style>
